<template>
<div class="form__field" v-bind:style="{...fieldStyles}">
    <div class="field__input" >
        <div class="label" v-if="label">
            {{ label }}
        </div>
        <div class="input__cont" v-if="input.type == 'text'">
            <input v-model="currentValue" 
                type="text" 
                :placeholder="placeholder"
                @input="updateCurrentValue"/>
        </div>
        <div class="input__cont" v-if="input.type == 'text-area'">
            <textarea v-model="currentValue"
                ref="textarea"
                v-bind:style="{...styles}"
                :placeholder="placeholder"
                @input="updateCurrentValue">
            </textarea>
        </div>
        <div class="input__cont" v-if="input.type == 'drop-button'">
            <DropButton
                :name="name"
                :items="input.items"
                :current="input.currentValue"
                :setter="updateCurrentValue"
                :placeholder="placeholder"
            />
        </div>
        <AboutMessage v-if="about"
            :message="about"
        />
    </div>
    <div v-if="annotation" class="annotation">{{annotation}}</div>
    <div v-if="erorrMessage" class="error__message">{{erorrMessage}}</div>
</div>
</template>

<script>
import DropButton from '../inputs/DropButton.vue';
import AboutMessage from '../AboutMessage.vue';

import {formBus} from './EventBus.js'

export default {
    name: 'Field',
    components: { 
        DropButton,
        AboutMessage,
    },
    created() {
        formBus.$on(`form-field-${this.name}`, 'view-error-message', ({message}) => {this.erorrMessage = message})
    },
    beforeDestroy() {
        formBus.$off(`form-field-${this.name}`)
    },
    computed: {
        currentValue:{
            get(){
                return this.input.currentValue
            },
            set(value){
                this.setter(value)
            }
        }
    },
    props: {
        input: {
            type: Object,
            required: true,
            default(){
                return {type: "text", currentValue: ""}
            },
            validator({type, currentValue}){
                if (!['text', 'text-area', 'drop-button'].includes(type)){
                    return false
                }

                if (type == "drop-button"){
                    return typeof currentValue == "number"
                } else if ( type == "text" || type == "text-area"){
                    return typeof currentValue == "string"
                }
                
                return typeof currentValue == "string"
            },
        },
        name: {
            type: String,
            required: true,
        },
        setter: {
            type: Function,
            required: true,
        },
        items: {
            type: Array || Object,
            default(){
                return []
            },
        },
        isRequired: {
            type: Boolean,
            default: false,
        },
        about: String,
        label: String,
        placeholder: String,
        annotation: String,
        
        styles: {
            type: Object,
            default: () => {
                return {}
            }
        },
        fieldStyles: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    data() {
        return {
            erorrMessage: undefined
        };
    },
    methods: {
        updateCurrentValue(index){
            this.clearErrorMessage()
            switch (this.input.type) {
                case "text":
                    this.setter(this.currentValue)
                    break;
                case "text-area":
                    this.setter(this.currentValue)
                    this.resize()
                    break;
                case "drop-button":
                    this.currentValue = index
                    this.setter(index)
                    break;
            }
        },
        clearErrorMessage(){
            if (this.erorrMessage){
                this.erorrMessage = undefined
            }
        },
        resize() {

            if (this.styles.height) return

            let element = this.$refs["textarea"];
            element.style.height = "19px";
            element.style.height= element.scrollHeight + "px";

            if (this.currentValue.length == 0){
                element.style.height = "19px";
                return
            }
        },
    },
};
</script>

<style scoped>
.field__input{
    display: flex;
    gap: 10px;
    width: 100%;
}

.input__cont{
    width: 100%;
}

.input__cont > input{
    width: calc(100% - 10px - 4px);
}

.input__cont > textarea{
    width: calc(100% - 10px - 4px);
}

.input__cont > .dropbutton{
    width: calc(100% );
}

.label{
    width: 174px;
    display: flex;
    align-items: center;
}

input {
    padding: 6px 6px 7px 6px;
    font-size: 16px;
    border-radius: 2px;
    border: 1px solid rgb(68, 68, 68);
}

textarea{
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    padding: 6px 6px 7px 6px;
    border-radius: 2px;
    border: 1px solid rgb(68, 68, 68);

    height: 19px;
    max-height: 70px;
    resize: none;
    overflow-y: auto;
}

input:focus, textarea:focus{
    outline: none;
}

input.disabled{
    background: #e9e9e9;
    color: grey;
}

.annotation{
    font-size: 10px;
    color: rgb(161, 151, 151)
}


.error__message{
    font-size: 10px;
    color: red;
}

.full-size{
    height: 100px;
}

</style>