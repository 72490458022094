<template>
    <div class="field__question" @click="handler" v-click-outside="close">
        <span
            class="iconify"
            data-icon="uil:question-circle"
            style="color: lightgray"
            data-width="30"
            data-height="30"
        ></span>
        <transition name="open">
            <div v-if="isOpen" class="message">
                {{ message }}
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: "AboutMessage",
    directives: {
        "click-outside": {
            bind: function (el, binding, vnode) {
                el.clickOutsideEvent = function (event) {
                    // here I check that click was outside the el and his children
                    if (!(el == event.target || el.contains(event.target))) {
                        // and if it did, call method provided in attribute value
                        vnode.context[binding.expression](event);
                    }
                };
                document.body.addEventListener("click", el.clickOutsideEvent);
            },
            unbind: function (el) {
                document.body.removeEventListener(
                    "click",
                    el.clickOutsideEvent
                );
            },
        },
    },
    props: {
        message: String,
    },
    data() {
        return {
            isOpen: false,
        };
    },
    methods: {
        handler() {
            this.isOpen = !this.isOpen;
        },
        close() {
            this.isOpen = false;
        },
    },
};
</script>

<style scoped>
.field__question {
    display: flex;
    align-items: center;
    position: relative;
}

.message {
    position: absolute;
    top: 38px;
    right: 0;
    background: white;
    border: 1px solid black;
    border-radius: 5px;
    padding: 10px;
    z-index: 2;
}

.open-enter-active {
    animation: open-in 0.5s;
}
.open-leave-active {
    animation: open-in 0.5s reverse;
}
@keyframes open-in {
    0% {
        transform: translate(10px, 0);
        opacity: 0;
    }
    80% {
        transform: translate(-10px, 0);
    }
    100% {
        transform: translate(0, 0);
        opacity: 1;
    }
}
</style>