var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"welcome"}},[_c('Header',{staticStyle:{"position":"inherit"},attrs:{"isAuthPage":true}}),_c('div',{staticClass:"welcome--cont"},[_vm._m(0),_c('div',{staticClass:"welcome--field--cont"},[_c('b-field',{attrs:{"type":_vm.form.shortNameError?
                'is-danger' : '',"message":_vm.form.shortNameError?
                _vm.form.shortNameError : 
                'Your short name being used in calendars'}},[_c('b-input',{attrs:{"maxlength":"30","placeholder":"Short name"},on:{"input":function () { return _vm.form.shortNameError = null; }},model:{value:(_vm.form.shortName),callback:function ($$v) {_vm.$set(_vm.form, "shortName", $$v)},expression:"form.shortName"}})],1),_c('b-field',{attrs:{"type":_vm.form.countryError?
                'is-danger' : '',"message":_vm.form.countryError?
                _vm.form.countryError : ''}},[_c('b-select',{attrs:{"placeholder":"Country","expanded":""},on:{"input":function (countryId) {
                    if (countryId)
                        { _vm.updateCountryId(countryId) }
                    _vm.form.countryError = null
                }}},_vm._l((_vm.countries),function(option){return _c('option',{key:("country-" + (option.id)),domProps:{"value":option.id}},[_vm._v(" "+_vm._s(option.name)+" ")])}),0)],1),_c('b-field',{attrs:{"type":_vm.form.timezoneError?
                'is-danger' : '',"message":_vm.form.timezoneError? 
                _vm.form.timezoneError : ''}},[_c('b-autocomplete',{attrs:{"data":_vm.search(_vm.timezones, _vm.searchTimezone),"field":"name","placeholder":"Timezone","clearable":""},on:{"input":function () { return _vm.form.timezoneError = null; },"blur":function () {
                        if (_vm.searchTimezone.length == 0)
                            { _vm.updateTimezoneId(_vm.form.timezoneId) }
                    },"select":function (option) {
                        if (option)
                            { _vm.updateTimezoneId(option.id) }
                    }},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_vm._v("No results found")]},proxy:true}]),model:{value:(_vm.searchTimezone),callback:function ($$v) {_vm.searchTimezone=$$v},expression:"searchTimezone"}})],1)],1)]),_c('div',{staticClass:"welcome--submit"},[_c('b-button',{attrs:{"type":"is-info","expanded":""},on:{"click":_vm.submit}},[_vm._v(" Submit ")])],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"welcome--title--cont"},[_c('div',{staticClass:"title"},[_vm._v(" Welcome to HoraGuru! ")]),_c('div',{staticClass:"description"},[_vm._v(" Please, set up key parameters required for proper calendar visualization! ")])])}]

export { render, staticRenderFns }