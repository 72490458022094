class Field {
	name
	isError = false
	errorMessage
	validator
	defaultErrorMessage

	constructor(name, defaultErrorMessage="") {
		this.name = name		
		this.defaultErrorMessage = defaultErrorMessage
	}

	error(){
		return this.isError?
			this.errorMessage ?? this.defaultErrorMessage:
			undefined
	}

	setError(message) {
		this.isError = true
		this.errorMessage = message
	}
}

export default Field