class WelcomeUserFormInstance {
    shortName = ""
    shortNameError = null
    countryId = -1 
    countryError = null
    timezoneId = -1
    timezoneError = null
    isAccept = true
    isAcceptError = null

    isValid(){
        if (this.shortName.length == 0) {
            this.shortNameError = "Please enter the short name"
            return false
        }

        if (this.countryId == -1) {
            this.countryError = "Please choose the country"
            return false
        }

        if (this.timezoneId == -1) {
            this.timezoneError = "Please choose the timezone"
            return false
        }

        if (this.isAccept == false) {
            this.isAcceptError = "Please accept service usage rules"
            return false 
        }

        return true
    }
}

export default WelcomeUserFormInstance