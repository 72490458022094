<template>
    <div class="dropbutton-container">
        <div class="dropbutton-field">
            <div class="dropbutton">
                <div class="dropbutton__current" v-bind:class="{
                    focus: isActive
                }">
                    <div v-if="isActive">
                        <div class="dropbutton__title" v-if="itemsLength > 8">
                            <input class="search" 
                            ref="search" 
                            type="text" 
                            placeholder="Search" 
                            :value="searchString"
                            @input="evt=>searchString=evt.target.value">
                        </div>
                        <div class="dropbutton__title" v-else @click="dropButton">
                            {{current == -1? placeholder : itemName(items[current])}} 
                        </div>
                    </div>

                    <div v-else @click="dropButton">
                        <div class="dropbutton__title" v-if="current == -1">
                            {{placeholder}}
                        </div>
                        <div class="dropbutton__title" v-else>
                            {{ itemName(items[current]) }}
                        </div>
                    </div>
                    <div class="dropbutton__icon__cont" @click="dropButton" v-bind:class="{
                        open: isActive,
                    }">
                        <span class="iconify"
                            data-icon="material-symbols:arrow-drop-down-rounded" 
                            data-width="24"
                            data-height="24">
                        </span>
                    </div>
                </div>
                <transition name="open">
                    <div class="dropbutton__item__list" v-if="isActive">
                        <div class="items__cont" v-if="dropButtonItems && dropButtonItems.length > 0">
                            <div v-for="(item, index) in dropButtonItems" 
                                :key="'dropbutton-item-' + index" 
                                class="dropbuttom__item"
                                @click="()=>selectItem(item,index)"
                            >
                                {{ itemName(item) }}
                            </div>
                        </div>
                        <div class="dropbuttom__no__Sitems" v-else>
                            No items
                        </div>

                        <div class="dropbuttom__item bordered" 
                        v-if="itemsLength > 0 && current != -1"
                        @click="() => {selectItem({}, -1)}">
                            Clear
                        </div>
                    </div>
                </transition>
            </div>
            <div class="dropbutton__about" v-if="about">
                <AboutMessage :message="about"/>
            </div>
        </div>
        <div class="dropbutton__error" v-if="field.isError">
            {{field.error()}}
        </div>
    </div>
</template>

<script>
import Form from '../../scripts/form'
import Field from '../../scripts/field'
import AboutMessage from '../AboutMessage.vue'
import { formBus } from './EventBus'

export default {
    name: "DropButton",
    
    components: {
        AboutMessage
    },

    props: {
        items: {
            type: [Array,Object],
        },
        current: Number,
        setter: Function,
        placeholder: {
            type: String,
            default: ""
        },
        about: {
            type: String,
        },
        form: {
            type: Form,
        },
        name: {
            type: String,
        }
    },
    created() {
        if (this.name){
            formBus.$on(`drop-button-${this.name}`, 'update-active', ({value}) => this.isActive = value)
        }
        if (this.form){
			this.form.addField(this.field)
		}
    },

    data() {
        return {
            searchString: "",
            isActive: false,
            isClosing: false,
            field:  new Field(this.name || "", "Selected value is not valid"),
        }
    },
    computed: {
        itemsLength(){
            if  (!this.items) return 0

            if (this.items.length){
                return this.items.length
            }

            return Object.values(this.items).length
        },
        dropButtonItems(){
            this.searchString
            if (!(this.itemsLength > 8 ) || this.searchString == ""){
                return this.items
            }
            
            let search = this.searchString.toLowerCase()
            let items = this.items
            if (!this.items.length){
                items = Object.values(this.items)
            }

            return items.filter(item => {
                if (item.title)
                    return item.title.toLowerCase().includes(search)
                else if (item.name)
                    return item.name.toLowerCase().includes(search)
                else if (item.Name)
                    return item.Name.toLowerCase().includes(search)
            }
            )
        }
    },  

    methods: {
        selectItem(item, index){
            if (this.field.isError) {
                this.field.isError = false
            }
            this.isActive = false
            if (index == -1){
                this.setter(-1, {})
                return
            }

            if (this.searchString.length > 0){
                let items = this.items
                if (!items.length){
                    items = Object.values(this.items)
                }
                index = items.findIndex(i => i === item)
            }

            this.setter(index, item)
        },
        dropButton(){
            this.isActive = !this.isActive
            formBus.$emitSimilarTypes('drop-button-', 'update-active', {value: false}, {
                senderType: `drop-button-${this.name}`,
            })
            if (this.itemsLength > 8){
                setTimeout(() => {
                    this.$refs["search"].focus()
                }, 200)
            }
        },
        itemName(item){
            if (item == undefined)
                return ""
            return item.name ?? item.title ?? item.Name
        }
    },
}
</script>

<style scoped>
.dropbutton-field{
    display: flex;
    flex-direction: row;
    gap: 10px;
}
.dropbutton{
    width: 100%;
    position: relative;
}
input:focus{
    outline: none;
    -webkit-box-shadow: 0px 0px 5px 3px rgba(34, 60, 80, 0.25);
    -moz-box-shadow: 0px 0px 5px 3px rgba(34, 60, 80, 0.25);
    box-shadow: 0px 0px 5px 3px rgba(34, 60, 80, 0.25);
}

.dropbutton__current{
    border: 1px solid;
    background-color: white;
    border-color: #dbdbdb;
    border-radius: 4px;
    color: #363636;

    display: grid;
    grid-template-columns: calc(100% - 32px) 32px;
    min-width: 140px;
    overflow: hidden; 
}

.dropbutton__current.focus{
    border-color: hwb(212 0% 0%);
    box-shadow: 0px 0 0 2px #cbe3ff;
}

.bordered{
    outline: 1px solid #c7c7c7;
    border-radius: 0 0 5px 5px;
}

input.search:focus{
	-webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.dropbutton__title{
    display: flex;
    align-items: center;

    width: 100%;
    font-size: 16px; 
    padding: 7px 11px;
    height: 40px;
    border: none;
    border-radius: 4px;
}

input.search{
    height: 19px;
    font-size: 16px;
    width: calc(100% - 12px);
    border: none;
}

.dropbutton__icon__cont{
    height: 100%;

    display: flex;
    justify-content: center;

    border-top-right-radius: inherit;
    border-bottom-right-radius: inherit;

    display: flex;
    justify-content: center;
    align-items: center;

    transition: .2s;
}

.dropbutton__icon__cont.open{
    transform: rotate(180deg);
}

.dropbutton__item__list{
    position: absolute;
    z-index: 5;
    top: 42px;
    
    border: 1px solid;
    background-color: white;
    border-color: #dbdbdb;
    border-radius: 4px;
    color: #363636;

    overflow: hidden;
    width: 100%;
}

.items__cont{
    overflow-y: auto;
    max-height: 148px;    
}


@keyframes dropbutton-active {
    from {
        max-height: 27px;
    }

    to {
        max-height: 148px;
    }
}

.open-enter-active {
  animation: dropbutton-active .2s ease-in-out;
}
.open-leave-active {
  animation: dropbutton-active .2s cubic-bezier(0.22, 0.61, 0.36, 1) reverse;
}

.dropbuttom__no__items{
    padding: 7px;
    color: gray;
    display: flex;
    justify-content:center;
    align-items: center;
}

.dropbuttom__item{
    padding: 9px 13px;
}

.dropbutton__error{
    font-size: 11px;
    color:red;
}
</style>